import { ApolloClient } from '@apollo/client/core';
import { useApolloClient } from '@vue/apollo-composable';
import type { Store } from 'pinia';

import type { CountryFeatures } from '../types';

import type {
  Actions as BookingActions,
  Getters as BookingGetters,
  State as BookingState,
} from '~~/domains/booking';
import type { CountriesQuery } from '~~/domains/graphql';
import { CountriesDocument } from '~~/domains/graphql';
import type {
  Actions as UserActions,
  Getters as UserGetters,
  State as UserState,
} from '~~/domains/user';

async function getPhonePrefixes(
  client: ApolloClient<any>,
): Promise<Array<{ countryCode: string; prefix: string }>> {
  try {
    const countries = await client.query<CountriesQuery>({
      query: CountriesDocument,
    });

    return countries.data.countries
      .map(country =>
        (country?.callingCodes ?? []).map(callingCode => ({
          countryCode: country.alpha2Code as string,
          prefix: callingCode as string,
        })),
      )
      .flat();
  } catch (error) {
    return [];
  }
}

function getBillingInfo(
  bookingStore: Store<'booking', BookingState, BookingGetters, BookingActions>,
  userStore: Store<'user', UserState, UserGetters, UserActions>,
) {
  return {
    address:
      bookingStore.beneficiary?.address1 ||
      userStore.billingAddress?.address1 ||
      '',
    extendAddress: [
      bookingStore.beneficiary?.address2 || userStore.billingAddress?.address2,
      bookingStore.beneficiary?.address3 || userStore.billingAddress?.address3,
      bookingStore.beneficiary?.address4 || userStore.billingAddress?.address4,
    ].filter(Boolean),
    zipCode:
      bookingStore.beneficiary?.zipcode ||
      userStore.billingAddress?.zipCode ||
      '',
    city:
      bookingStore.beneficiary?.city || userStore.billingAddress?.city || '',
  };
}

async function detailsParametersBuilder(
  bookingStore: Store<'booking', BookingState, BookingGetters, BookingActions>,
  userStore: Store<'user', UserState, UserGetters, UserActions>,
) {
  const { client } = useApolloClient();

  const phonePrefixes = await getPhonePrefixes(client);

  if (!bookingStore) return '';

  const localePath = useLocalePath();
  const redirectUrl = new URL(
    localePath({
      name: 'booking-details',
      query: bookingStore.routeParameters,
    }),
    window.location.origin,
  );
  const redirect = encodeURIComponent(redirectUrl.toString());

  const phonePrefix = phonePrefixes.find(
    ({ countryCode, prefix }) =>
      countryCode.toUpperCase() ===
        (bookingStore.beneficiary?.phone.prefix ?? '').toUpperCase() ||
      prefix === bookingStore.beneficiary?.phone.prefix ||
      prefix === userStore.phoneNumber?.phonePrefix,
  );

  const data: Record<string, unknown> = {
    userData: {
      billad: true,
      userInfos: {
        billing: getBillingInfo(bookingStore, userStore),
        civility:
          bookingStore.beneficiary?.civilityCode ||
          userStore.civilInformation?.civility ||
          '',
        firstName:
          bookingStore.beneficiary?.firstName ||
          userStore.civilInformation?.firstName ||
          '',
        lastName:
          bookingStore.beneficiary?.lastName ||
          userStore.civilInformation?.lastName ||
          '',
        country:
          bookingStore.beneficiary?.countryCode ||
          userStore.billingAddress?.countryCode ||
          '',
        nationality:
          bookingStore.beneficiary?.nationalityCode ||
          userStore.billingAddress?.countryCode ||
          '',
        email:
          bookingStore.beneficiary?.email ||
          userStore.emailContact?.email ||
          '',
        phone: {
          prefix: phonePrefix?.prefix,
          number:
            bookingStore.beneficiary?.phone?.number ||
            userStore.phoneNumber?.phoneNumber,
        },
        stayReason:
          (bookingStore.tripType || 'leisure') === 'leisure'
            ? 'PERSONAL'
            : 'BUSINESS',
        isLogged: userStore.isLogged,
      },
    },
    ssr: true,
    basketId: bookingStore.basketId || '',
    redirect,
    params: {},
  };

  return btoa(JSON.stringify(data));
}

const countryConfiguration: CountryFeatures = runtimeConfig => ({
  booking: {
    details: {
      active: true,
      externalUrl: runtimeConfig.public.featureRussianExternalUrl,
      parametersBuilder: detailsParametersBuilder,
      forcePersistence: true,
      lockFormInput: true,
    },
    rateSelectPopup: {
      active: true,
      showSignIn: true,
      showWithoutAccount: true,
    },
  },
});

export default countryConfiguration;
