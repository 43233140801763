import type { Country } from '../types';

export function useCountryFromLanguage(enforceValidation = true) {
  const { locale } = useI18n();
  const localizationHeader = useLocalizationHeader();

  const countryFromLocale = computed<Country>(() => {
    switch (locale.value) {
      case 'ru':
        return 'russian';

      case 'en':
      default:
        return 'english';
    }
  });

  const countryValidated = computed(() =>
    validateCountry(countryFromLocale, localizationHeader),
  );

  return computed<Country>(() => {
    if (enforceValidation) {
      return countryValidated.value ? countryFromLocale.value : 'english';
    }

    return countryFromLocale.value;
  });
}

/**
 * Country validators (eg : Russia need to be located in Russia in addition to being set as the website locale)
 */
function validateCountry(
  country: ComputedRef<Country>,
  localizationHeader: ComputedRef<string | undefined | null>,
) {
  switch (country.value) {
    case 'russian':
      return validateRU(localizationHeader);

    case 'english':
    default:
      return true;
  }
}

function validateRU(
  localizationHeader: ComputedRef<string | null | undefined>,
): boolean {
  return localizationHeader.value?.toUpperCase?.() === 'RU';
}
