import { useSessionStorage } from '@vueuse/core';
import type { RouteLocationNormalized } from 'vue-router';

import type { BookingDetails } from '~~/domains/feature';
import { getFeatureForCountry } from '~~/domains/feature';
import { getLanguageFromRoute } from '~~/helpers';

const MAX_HISTORY = 30;

export function useAppHistory() {
  const router = useRouter();
  const history = useSessionStorage(
    'app-history',
    [] as { to: RouteLocationNormalized; from: RouteLocationNormalized }[],
    { deep: true },
  );

  router.afterEach(
    (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      history.value.unshift({ to, from });

      if (history.value.length) {
        history.value = history.value.slice(0, MAX_HISTORY);
      }
    },
  );

  function getLatestRouteMatching(
    partialRouteName: string,
  ): RouteLocationNormalized | undefined {
    if (!history.value.length) return;

    const item = history.value.find(({ to }) =>
      to.name?.toString().includes(partialRouteName),
    );

    return item?.to;
  }

  function getLatestRouteMatchingDelta(
    partialRouteName: string,
    isLogged = false,
  ): number | undefined {
    if (!history.value.length) return;

    const itemIndex = history.value.findIndex(({ to }) =>
      to.name?.toString().includes(partialRouteName),
    );

    if (itemIndex <= 0) return 0;

    const country = getLanguageFromRoute(history.value[itemIndex].to) as string;
    const feature = getFeatureForCountry<BookingDetails>(
      'booking.details',
      country,
    );

    return feature?.active && feature.externalUrl && !isLogged
      ? itemIndex + 2
      : itemIndex;
  }

  function hasVisitedRoute(partialRouteName: string) {
    if (!history.value.length) return false;

    return history.value.some(({ to }) =>
      to.name?.toString().includes(partialRouteName),
    );
  }

  return {
    getLatestRouteMatching,
    getLatestRouteMatchingDelta,
    hasVisitedRoute,
  };
}
