export enum SupportedLanguages {
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  RUSSIAN = 'ru',
  TURKISH = 'tr',
  TRANSLATIONS = 'xx',
}

export enum SupportedLocales {
  ENGLISH = 'en-US',
  FRENCH = 'fr-FR',
  GERMAN = 'de-DE',
  RUSSIAN = 'ru-RU',
  TURKISH = 'tr-TR',
  TRANSLATIONS = 'xx-XX',
}

export const ENABLED_LANGUAGES = [
  SupportedLanguages.ENGLISH,
  SupportedLanguages.FRENCH,
  SupportedLanguages.GERMAN,
  SupportedLanguages.RUSSIAN,
  SupportedLanguages.TURKISH,
  SupportedLanguages.TRANSLATIONS,
];

/**
 * Supported brand for name and icon
 * When adding a new brand remember to update the linked file
 * @link ~~/helpers/brand.helper.ts
 */
export type SupportedBrands =
  | 'AAI'
  | 'ALI'
  | 'FAI'
  | 'HYD'
  | 'RIX'
  | 'SLS'
  | 'SO'
  | 'SWI';

// @see https://jira.kaliop.net/browse/ACCORDAI-81
export const CountryToCurrencyMapping = {
  TR: 'TRY',
  RU: 'RUB',
  AE: 'AED',
  GB: 'GBP',
  KZ: 'KZT',
  UA: 'UAH',
  DE: 'EUR',
  US: 'USD',
  AZ: 'AZN',
  EG: 'USD',
  RO: 'EUR',
  SA: 'SAR',
  CH: 'CHF',
  IR: 'IRR',
  FR: 'EUR',
  NL: 'EUR',
  BE: 'EUR',
  IT: 'EUR',
} as const;

export const DEFAULT_LANGUAGE = SupportedLanguages.ENGLISH;

export const DEFAULT_LOCALE = SupportedLocales.ENGLISH;

export const DEFAULT_CURRENCY = CountryToCurrencyMapping.US;

export const DEFAULT_BRAND: SupportedBrands = 'ALI';

export const DATE_PICKER_MAX_RANGE = 30;

export const SESSION_STORAGE_KEYS = {
  BASKET_AUTH_CREATION: 'aic_auth_basket_status',
  BASKET_ID: 'booking::basketID',
  BENEFICIARY: 'booking::beneficiary',
  BOOKING_URL: 'booking::URL',
  SPECIAL_REQUEST: 'booking::specialRequest',
  TRIP_TYPE: 'booking::tripType',
  POST_BOOKING_PAYLOAD: 'booking::postBookingPayload',
  EXTERNAL_DATA_SESSION_KEY: 'feat.external-data',
  PAYMENT_METHOD: 'booking::paymentMethod',
};
