import { SESSION_STORAGE_KEYS } from '~~/core';
import { useBookingStore } from '~~/domains/booking';
import { useIdentificationPlainTextLazyQuery } from '~~/domains/graphql';
import { useUserStore } from '~~/domains/user';

export function useIdentificationPlaintext() {
  const userStore = useUserStore();
  const bookingStore = useBookingStore();

  const identificationCookies = [
    '_Hw2h_',
    'JSESSIONID',
    'org',
    'identification_com.allinclusive',
  ];

  const promotionalInformations = {
    cardNumber: userStore.loyaltyCardNumber,
    ...bookingStore.promotionalInformation,
  };

  const { load, document, onResult, onError } =
    useIdentificationPlainTextLazyQuery(promotionalInformations, {
      fetchPolicy: 'no-cache',
    });

  const parseCookies = () => {
    const rawCookies = window.document.cookie.split('; ');

    return rawCookies.reduce(
      (acc, cookie) => {
        const [key, ...value] = cookie.split('=');

        acc[key] = value.join('=');

        return acc;
      },
      {} as Record<string, any>,
    );
  };

  onResult(result => {
    if (result.loading) return;

    userStore.hasIdentificationCookies = true;
    Object.values(SESSION_STORAGE_KEYS).forEach(value => {
      if (value.includes('booking::')) {
        window.sessionStorage.removeItem(value);
      }
    });
  });

  onError(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });

  const cookies = parseCookies();
  const isIdentified = identificationCookies.every(cookie =>
    Boolean(cookies[cookie]),
  );

  if (!isIdentified) {
    load(document.value, promotionalInformations);
  } else {
    userStore.hasIdentificationCookies = true;
  }
}
