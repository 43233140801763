<template>
  <header class="AiHeader Layout">
    <div class="AiHeader-side">
      <language-header-picker
        v-model="languageOpened"
        :inverse="inverse"
        :available-locales="availableLocales"
        :route-localization="routeLocalization" />

      <currency-header-picker v-model="currencyOpened" :inverse="inverse" />
    </div>

    <div ref="burgerMenuMobile" class="AiHeader-side--mobile">
      <ai-button
        class="AiHeader-side-button"
        variant="tertiary"
        :aria-label="$t('header.menuToggleButton')"
        @click="mobileMenuOpened = true">
        <ai-icon
          name="burger_menu"
          :size="48"
          class="AiHeader-side-icon"
          :class="{ 'AiHeader-side-icon--inverse': inverse }" />
      </ai-button>
    </div>

    <div class="AiHeader-logoContainer">
      <ai-link :url="localePath({ name: 'index' })">
        <ai-logo
          brand="AAI"
          :size="{ height: 32, width: 110 }"
          class="AiHeader-logo"
          :class="{ 'AiHeader-logo--inverse': inverse }"
          :aria-label="$t('header.AiLogo')" />
      </ai-link>
    </div>

    <div class="AiHeader-side">
      <ai-header-menu
        data-testid="myAccount"
        :label="$t('header.myAccount')"
        :inverse="inverse"
        :open="isAccountOpen"
        controls="AiHeader-menu-myAccount"
        @click="toggleAccount">
        <template #inner>
          <account-submenu
            ref="myAccountSubmenu"
            :inverse="inverse"
            class="AiHeader-accountSubmenu"
            @close="toggleAccount" />
        </template>

        <template #left>
          <ai-icon
            name="account"
            :size="16"
            class="AiHeader-side-icon"
            :class="{ 'AiHeader-side-icon--inverse': inverse }" />
        </template>
      </ai-header-menu>

      <ai-button
        v-if="showSearchCta"
        :label="$t('header.findResort')"
        :url="localePath({ name: 'search' })"
        class="Aiheader-findResort"
        as-link
        light />
    </div>

    <div class="AiHeader-side--mobile">
      <ai-button
        ref="myAccountMobileToggle"
        data-testid="myAccountMobile"
        class="AiHeader-side-button"
        variant="tertiary"
        :aria-label="$t('header.myAccount')"
        aria-controls="AiHeader-menu-myAccount"
        :aria-expanded="isAccountOpen"
        @click="toggleAccount">
        <ai-icon
          name="account"
          :size="32"
          class="AiHeader-side-icon"
          :class="{ 'AiHeader-side-icon--inverse': inverse }" />
      </ai-button>
      <transition name="open">
        <account-submenu
          v-show="isAccountOpen"
          ref="myAccountMobileSubmenu"
          :inverse="inverse"
          class="AiHeader-accountSubmenu--mobile"
          @close="isAccountOpen = false" />
      </transition>
    </div>

    <ai-header-burger-menu
      v-model="mobileMenuOpened"
      :inverse="inverse"
      :available-locales="availableLocales"
      :suggested-currencies="suggestedCurrencies"
      :available-currencies="availableCurrencies"
      @close="onBurgerMenuClose" />
  </header>
  <div v-if="showSearchCta" class="Aiheader-mobileFindResortBlock">
    <ai-button
      :label="$t('header.findResort')"
      :url="localePath({ name: 'search' })"
      class="Aiheader-mobileFindResortButton"
      as-link
      light />
  </div>
</template>

<script setup lang="ts">
// TODO: once header is completely finished, it should be moved outside the ux folder
import type { LocaleObject } from '@nuxtjs/i18n';
import type { RouteLocationRaw } from 'vue-router';

import AccountSubmenu from '~/domains/layout/components/AccountSubmenu/AccountSubmenu.vue';
import { useCurrencies, useLanguagesMap } from '~/domains/referential';

import AiButton from '../../atoms/AiButton/AiButton.vue';
import AiIcon from '../../atoms/AiIcon/AiIcon.vue';
import AiLink from '../../atoms/AiLink/AiLink.vue';
import AiLogo from '../../atoms/AiLogo/AiLogo.vue';

import AiHeaderBurgerMenu from './AiHeaderBurgerMenu.vue';
import AiHeaderMenu from './AiHeaderMenu.vue';

type Props = {
  inverse?: boolean;
  routeLocalization?: Record<string, RouteLocationRaw>;
  showSearchCta?: boolean;
};

defineProps<Props>();

const localePath = useLocalePath();

const { locale, locales } = useI18n();

const mobileMenuOpened = ref(false);

const languageOpened = ref(false);

const currencyOpened = ref(false);

const burgerMenuMobile = ref<HTMLDivElement | null>(null);
const myAccountMobileSubmenu = ref<ComponentPublicInstance | null>(null);
const myAccountSubmenu = ref<ComponentPublicInstance | null>(null);
const myAccountMobileToggle = ref<ComponentPublicInstance | null>(null);

const apiLanguages = useLanguagesMap();
const availableLocales = computed(() =>
  (locales.value as LocaleObject[])
    .filter(
      item => item.code !== locale.value && item.code in apiLanguages.value,
    )
    .map(item => ({
      ...item,
      name: apiLanguages.value[item.code]?.label || item.name,
    })),
);

const apiCurrencies = useCurrencies();
const availableCurrencies = computed(() => {
  return Array.from(apiCurrencies.value).sort((a, b) =>
    a.code > b.code ? 1 : -1,
  );
});
const suggestedCurrencies = computed(() => {
  const suggestedCurrenciesCodes = ['USD', 'EUR', 'RUB', 'SAR', 'TRY', 'AED'];

  return Array.from(apiCurrencies.value)
    .filter(currency => suggestedCurrenciesCodes.includes(currency.code))
    .sort(
      (a, b) =>
        suggestedCurrenciesCodes.indexOf(a.code) -
        suggestedCurrenciesCodes.indexOf(b.code),
    );
});

const isAccountOpen = ref(false);

const closeAll = () => {
  isAccountOpen.value = false;
  currencyOpened.value = false;
  languageOpened.value = false;
};

const toggleAccount = () => {
  if (isAccountOpen.value) {
    isAccountOpen.value = false;
    nextTick(() => {
      myAccountMobileToggle?.value?.$el.focus();
    });
    return;
  }

  closeAll();
  isAccountOpen.value = true;
  nextTick(() => {
    const firstButtonMobile =
      myAccountMobileSubmenu?.value?.$el.querySelector('.AiButton');
    firstButtonMobile?.focus();

    const firstButtonDesktop =
      myAccountSubmenu?.value?.$el.querySelector('.AiButton');
    firstButtonDesktop?.focus();
  });
};

const onBurgerMenuClose = () => {
  if (!burgerMenuMobile.value) {
    return;
  }

  const burgerToggleMenuButton = burgerMenuMobile.value.querySelector(
    '.AiButton',
  ) as HTMLButtonElement;

  burgerToggleMenuButton?.focus();
};

defineExpose({ closeAll });
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/functions' as func;

.AiHeader {
  position: relative;
  height: func.calcRem(48);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: constants.$margin-00;
  transition: all 300ms;

  @media (mq.$from-medium) {
    margin-top: constants.$margin-01;
  }
}

.AiHeader-side {
  display: none;

  @media (mq.$from-medium) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: constants.$margin-03;
    width: 100%;
  }

  &--mobile {
    @media (mq.$from-medium) {
      display: none;
    }
  }
}

.AiHeader-side-button {
  height: auto;
}

.AiHeader-side-icon {
  color: colors.$gold-700;
  transition: all 300ms;

  &--inverse {
    color: colors.$gold-300;
  }
}

.AiHeader-logoContainer + .AiHeader-side {
  justify-content: flex-end;
}

.AiHeader-logoContainer {
  margin-inline: constants.$margin-01;
}

.AiHeader-logo {
  color: colors.$gold-900;
  transition: all 300ms;

  &--inverse {
    color: colors.$gold-300;
  }
}

.AiHeader-burgerMenu {
  color: colors.$gold-900;
  transition: color 300ms;

  &--inverse {
    color: colors.$gold-300;
  }
}

.AiHeader-side-localeLabel {
  text-transform: uppercase;
}

.AiHeader-accountSubmenu {
  position: absolute;
  right: 0;
  top: constants.$margin-04;
}

.AiHeader-accountSubmenu--mobile {
  overflow: hidden;
  max-height: 500px; // should always be bigger than the real height to animate
  position: absolute;
  z-index: 3;
  left: func.calcRem(-16); // remove mobile padding
  right: func.calcRem(-16); // remove mobile padding
  top: 100%;
}

.Aiheader-findResort {
  color: colors.$stratos-900;
  @include mixins.rem-fallback(min-height, 40);
}

.open-enter-active,
.open-leave-active {
  transition: max-height 0.3s ease-out;
}

.open-enter-from,
.open-leave-to {
  max-height: 0;
}

.Aiheader-mobileFindResortBlock {
  padding: 0 constants.$margin-02;

  @media (mq.$from-medium) {
    display: none;
  }
}

.Aiheader-mobileFindResortButton {
  width: 100%;
}
</style>
