import type { RouteLocationNormalized } from 'vue-router';

export function getLanguageFromRoute(route?: RouteLocationNormalized) {
  if (!route) return;

  const matches = /^\/?([a-z]{2})\//.exec(route.fullPath);

  if (!matches) return;

  const [, country] = matches;

  return country;
}
