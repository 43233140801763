<template>
  <NuxtPage />
</template>

<script lang="ts" setup>
import type { ReactiveHead } from '@unhead/vue';

const runtimeConfig = useRuntimeConfig();

useAppHistory();

const { locale } = useI18n();
const route = useRoute();
const isPreview = computed(() => route.query.draft === 'true');

const head = computed<ReactiveHead>(() => {
  const meta = [
    {
      name: 'google-site-verification',
      content: 'QFTRdQRgXFSTOfNL5wYw56SzYdbHzGPfEp7M131P0us',
    },
  ];

  if (isPreview.value) {
    meta.push(
      { name: 'robots', content: 'noindex, nofollow' },
      { name: 'googlebot', content: 'noindex, nofollow' },
    );
  }

  const script: ReactiveHead['script'] = [
    {
      async: false,
      'data-document-language': true,
      'data-domain-script': runtimeConfig.public.oneTrustToken,
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    },
    {
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://sgtm.allinclusive-collection.com/ixzn81lqx6jltmk.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KBV232K');`,
    },
  ];

  if (process.env.NEW_RELIC_ENABLED === 'true') {
    script.push({ src: `/newrelic.${process.env.NUXT_PUBLIC_APP_ENV}.js` });
  }

  return {
    htmlAttrs: {
      lang: locale.value,
    },
    script,
    meta,
  };
});

useHead(head);

if (process.client) {
  useIdentificationPlaintext();
}
</script>

<style lang="scss">
@import '@/assets/styles/main';
@import '@/assets/styles/print';
</style>
