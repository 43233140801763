import type { UseQueryReturn } from '@vue/apollo-composable';
import type {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core';

export function useAsyncQuery<TResult, TVariables extends OperationVariables>(
  invocatedQuery: UseQueryReturn<TResult, TVariables>,
): Promise<ApolloQueryResult<TResult>> {
  return new Promise((resolve, reject) => {
    const { onResult, onError } = invocatedQuery;

    onResult(resolve);
    onError(reject);
  });
}
