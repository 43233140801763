<template>
  <ai-header-picker
    v-model="bookingStore.currentCurrency"
    data-testid="currencySelect"
    :inverse="inverse"
    :opened-value="currencyOpened"
    :label="$t('header.mobile.currency')"
    controls="AiHeaderPicker-currency-switcher"
    @update:opened-value="currencyOpened = $event">
    <template #menu="{ close }">
      <div ref="container" class="AiHeader-dropdownCurrency">
        <button
          v-for="suggestedCurrency in suggestedCurrencies"
          :key="suggestedCurrency.code"
          class="AiHeader-side-currencyItem"
          :class="{ 'AiHeader-side-currencyItem--inverse': inverse }"
          @click="() => onSelectCurrency(suggestedCurrency.code, close)">
          <ai-typo
            as="span"
            variant="overline-01"
            class="AiHeader-side-currencyLabel"
            :color="inverse ? 'white' : undefined">
            {{ suggestedCurrency.code }} - {{ suggestedCurrency.value }}
          </ai-typo>
        </button>
        <div class="AiHeader-side-currencySeparator">
          <ai-separator margin="none" :inverse="inverse" />
        </div>
        <button
          v-for="availableCurrency in currenciesDisplayed"
          :key="availableCurrency.code"
          class="AiHeader-side-currencyItem"
          :class="{ 'AiHeader-side-currencyItem--inverse': inverse }"
          @click="() => onSelectCurrency(availableCurrency.code, close)">
          <ai-typo
            as="span"
            variant="overline-01"
            class="AiHeader-side-currencyLabel"
            :color="inverse ? 'white' : undefined">
            {{ availableCurrency.code }} - {{ availableCurrency.value }}
          </ai-typo>
        </button>
      </div>
    </template>
  </ai-header-picker>
</template>

<script setup lang="ts">
import { useBookingStore } from '~~/domains/booking';
import { useCurrencies } from '~~/domains/referential';

type Props = {
  modelValue: boolean;
  inverse?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const currencyOpened = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

watch(currencyOpened, opened => {
  nextTick(() => {
    if (!container.value?.parentElement) return;

    if (opened) {
      container.value.parentElement.addEventListener(
        'scroll',
        onContainerScroll,
      );
    } else {
      container.value.parentElement.removeEventListener(
        'scroll',
        onContainerScroll,
      );
    }
  });
});

const bookingStore = useBookingStore();
const apiCurrencies = useCurrencies();

const container = ref<HTMLElement>();

const availableCurrencies = computed(() => {
  return Array.from(apiCurrencies.value).sort((a, b) =>
    a.code > b.code ? 1 : -1,
  );
});

const currenciesStep = 10;
const currenciesShownCount = ref(10);
const currenciesDisplayed = computed(() =>
  availableCurrencies.value.slice(0, currenciesShownCount.value),
);

const suggestedCurrencies = computed(() => {
  const suggestedCurrenciesCodes = [
    'GBP',
    'USD',
    'EUR',
    'RUB',
    'SAR',
    'TRY',
    'AED',
  ];

  return Array.from(apiCurrencies.value)
    .filter(currency => suggestedCurrenciesCodes.includes(currency.code))
    .sort(
      (a, b) =>
        suggestedCurrenciesCodes.indexOf(a.code) -
        suggestedCurrenciesCodes.indexOf(b.code),
    );
});

const onSelectCurrency = (currencyCode: string, close: () => void) => {
  bookingStore.setCurrency(currencyCode);
  close();
};

const onContainerScroll = (event: Event) => {
  const { clientHeight, scrollHeight, scrollTop } =
    event.target as HTMLDivElement;

  const percentageScrolled = ((clientHeight + scrollTop) * 100) / scrollHeight;

  if (percentageScrolled >= 95) {
    currenciesShownCount.value += currenciesStep;
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/functions' as func;

.AiHeader-dropdownCurrency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.AiHeader-side-currencyItem {
  padding: constants.$inner-02 constants.$inner-04;
  transition: all 300ms;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: colors.$gold-100;
  }

  &--inverse:hover {
    background-color: colors.$neutral-700;
  }
}

.AiHeader-side-currencyLabel {
  text-transform: uppercase;
  white-space: nowrap;
}

.AiHeader-side-currencySeparator {
  width: 100%;
  padding: constants.$inner-02 constants.$inner-04;
}
</style>
