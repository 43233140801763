<template>
  <ai-skiplinks :inverse="inverse" />
  <div class="DefaultLayout-content">
    <div
      class="DefaultLayout-header"
      :class="{
        'DefaultLayout-header--stickyDisabled': disableStickyHeader,
        'DefaultLayout-header--hidden': !visible,
        'DefaultLayout-header--inverse': inverse && !scrolled,
        'DefaultLayout-header--subheader': subheader,
      }">
      <ai-header
        ref="header"
        :inverse="inverse && !scrolled"
        :route-localization="routeLocalization"
        :show-search-cta="!hideSearchCta" />
      <slot name="subheader" :inverse="inverse && !scrolled" />
    </div>

    <main id="main-content">
      <slot />
    </main>

    <global-footer v-if="!hideFooter" />

    <button
      v-if="!hideScrollToTop"
      class="AiLayout-scrollToTop"
      :class="{ 'AiLayout-scrollToTop--visible': yOffset > 20 }"
      @click="scrollToTop">
      <ai-icon
        name="chevron"
        :size="24"
        class="AiLayout-scrollToTop-icon"
        :description="$t('accessibility.description.backToTop')" />
    </button>
  </div>
</template>

<script setup lang="ts">
import GlobalFooter from './GlobalFooter/GlobalFooter.vue';

import { useUserStore } from '~~/domains/user';
import { useLayoutStore } from '~~/domains/ux';
import { useHeaderScrollApparition } from '~~/domains/ux/hooks/useHeaderScrollApparition';
import AiHeader from '~~/domains/ux/organisms/AiHeader/AiHeader.vue';

type Props = {
  inverse?: boolean;
  hideSearchCta?: boolean;
  hideFooter?: boolean;
  hideScrollToTop?: boolean;
  disableStickyHeader?: boolean;
  subheader?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  disableStickyHeader: false,
  inverse: false,
  hideSearchCta: false,
  hideFooter: false,
  hideScrollToTop: false,
  subheader: false,
});

const layoutStore = useLayoutStore();
const userStore = useUserStore();

const header = ref<typeof AiHeader>();

const routeLocalization = computed(() => layoutStore.header.routeLocalization);

const { visible, scrolled } = useHeaderScrollApparition({
  disabled: props.disableStickyHeader,
  threshold: 20,
});
const { scrollToTop } = useScrollToTop();

const router = useRouter();

router.beforeEach(() => {
  layoutStore.$patch({ header: { routeLocalization: {} } });
});

userStore.loadUser();
userStore.sendLoginEvent();

const yOffset = ref(0);

onMounted(() => {
  window.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

const onScroll = () => {
  const newScroll = window.scrollY || document.documentElement.scrollTop;

  if (newScroll > yOffset.value && header.value) {
    header.value.closeAll();
  }

  yOffset.value = newScroll;
};

watch(
  () => layoutStore.isScrollLocked,
  isScrollLocked => {
    // The class is added to the body to prevent the scroll on the background and avoid to have a blank screen under the popin
    if (isScrollLocked) {
      document.body.classList.add('scroll-disabled');
    } else {
      document.body.classList.remove('scroll-disabled');
    }
  },
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';

.AiSkiplinks-nav:focus-within + .DefaultLayout-content {
  transform: translateY(calc(constants.$inner-06 * 1.5));

  @media (mq.$from-medium) {
    transform: none;
  }
}

.DefaultLayout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;
  background-color: colors.$white;
  padding-bottom: constants.$padding-00;
  transform: translateY(0);
  transition: all 300ms;

  @media (mq.$from-medium) {
    padding-bottom: constants.$padding-01;
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &--inverse {
    background-color: transparent;
  }

  &--stickyDisabled {
    position: absolute;
  }

  &--subheader {
    padding-bottom: 1px;
  }
}

.DefaultLayout-content {
  min-height: calc(100vh - 60px - 50px);
}

.AiLayout-scrollToTop {
  position: fixed;
  right: constants.$margin-02;
  bottom: constants.$margin-02;
  line-height: 0;
  min-height: 4rem;
  min-width: 4rem;
  border-radius: 100%;
  background-color: rgba(colors.$gold-900, 0.5);
  opacity: 0;
  z-index: -1;
  transition: all 300ms;

  &--visible {
    opacity: 1;
    z-index: auto;
  }
}

.AiLayout-scrollToTop-icon {
  color: colors.$white;
}
</style>
