<template>
  <ai-header-picker
    v-model="language"
    v-model:opened-value="languageMenuOpened"
    :inverse="inverse"
    :label="$t('header.mobile.language')"
    controls="AiHeaderPicker-lang-switcher">
    <template #menu>
      <ul>
        <li
          v-for="availableLocale in localizedPaths"
          :key="availableLocale.code"
          class="AiHeader-side-localeItem"
          :class="{ 'AiHeader-side-localeItem--inverse': inverse }">
          <ai-link
            :url="availableLocale.path"
            typo-variant="overline-01"
            class="AiHeader-side-localeLabel"
            :color="inverse ? 'white' : undefined"
            :lang="availableLocale.code"
            :hreflang="availableLocale.code"
            undecorated
            force-anchor>
            {{ availableLocale.name }}
          </ai-link>
        </li>
      </ul>
    </template>
  </ai-header-picker>
</template>

<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n';
import type {
  RouteLocation,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';

import { useLayoutStore } from '~/domains/ux';

import { ENABLED_LANGUAGES, SupportedLanguages } from '~~/core';

type Props = {
  modelValue: boolean;
  availableLocales: LocaleObject[];
  routeLocalization?: Record<string, RouteLocationRaw>;
  inverse?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: boolean): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { locale } = useI18n();
const localePath = useLocalePath();
const route = useRoute();

const layoutStore = useLayoutStore();

const languageMenuOpened = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emits('update:modelValue', value);
  },
});

const language = computed(() => locale.value);

const locales = computed(() =>
  props.availableLocales.filter(availableLocale =>
    ENABLED_LANGUAGES.includes(availableLocale.code as SupportedLanguages),
  ),
);

const queryParameterLocalization = computed(
  () => layoutStore.header.queryParameterLocalization,
);

const localizedPaths = computed(() =>
  locales.value.map(locale => {
    const override = props.routeLocalization?.[locale.code];

    const { name, query, params } = route;
    const routeName = name?.toString().split('__')[0];

    const newRoute: Partial<RouteLocationNormalized> = {
      name: routeName,
      query: { ...(query || {}) },
      params: { ...(params || {}) },
    };

    if (override) {
      Object.assign(newRoute, override);
    }

    if (typeof queryParameterLocalization.value === 'function') {
      const override = queryParameterLocalization.value(newRoute, locale.code);

      if (override) {
        Object.assign(newRoute, override);
      }
    }

    return {
      ...locale,
      path: localePath(newRoute as RouteLocation, locale.code),
    };
  }),
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/functions' as func;

.AiHeader-side-localeItem {
  padding: constants.$inner-02 constants.$inner-04;
  transition: all 300ms;

  &:hover {
    background-color: colors.$gold-100;
  }

  &--inverse:hover {
    background-color: colors.$neutral-700;
  }
}
</style>
