import _defaults from 'lodash/defaults';

import { useUserStore } from '~~/domains/user';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

type PageViewData = {
  page_name?: string;
  page_language?: string;
  page_category?: string;
  page_sub_category_level_1?: string;
  page_sub_category_level_2?: string;
  page_sub_category_level_3?: string;
};

type PageViewOptions = {
  sendUserData: boolean;
};

type PageViewPayload = {
  event: string;
  page_data: Record<string, unknown>;
  user_data?: Record<string, unknown>;
};

export type ECommerceDataItem = {
  index?: number;
  item_id?: string;
  item_name?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_brand?: string;
  item_variant?: string;
  price?: string | number;
  location_id?: string;
  quantity?: number;
  night_nb?: number | string;
};

type ECommerceData = {
  items: ECommerceDataItem[];
  item_list_id?: string;
  item_list_name?: string;
  value?: number | string;
  tax?: number | string;
  currency?: string;
  payment_type?: string;
  transaction_id?: string;
};

export const getPageName = (routeName: string) => {
  // Remove the locale from the route name
  const page = routeName.split('__')[0] ?? 'unknown';

  // Specific case for the home page
  if (page === 'index') {
    return 'home';
  }

  // Specific case for the hotel page as the route contains the hotel slug
  if (page.startsWith('hotel')) {
    return 'hotel';
  }

  return page;
};

const getUserLoyaltyStatusPoints = (points: number) => {
  if (points < 2000) {
    return '0 - 2000';
  }

  if (points < 7000) {
    return '2000 - 7000';
  }

  if (points < 14000) {
    return '7000 - 14000 ';
  }

  return '14000+';
};

const getUserData = () => {
  const userStore = useUserStore();

  if (!userStore.isLogged) {
    return {
      all_member: 'not logged',
      user_is_logged: '0',
    };
  }

  const points = userStore.user.loyalty?.balances?.nbPoints ?? 0;

  return {
    all_member: userStore.isLoyalUser ? 'yes' : 'no',
    member_status: userStore.loyaltyCardLabel,
    uid: userStore.user.id,
    user_country_code: userStore.user.individual?.nationality,
    user_id: userStore.user.individual?.uaUserId,
    user_is_logged: '1',
    user_loyalty_card: userStore.loyaltyCardCode,
    user_loyalty_status_points: getUserLoyaltyStatusPoints(points),
  };
};

/**
 * /!\ Don't use i18n hooks here, as it might not be initialized
 */
const extractLanguageFromUrl = () => {
  if (!process.client) return '';

  const pathname = window.location.pathname;
  const regexResult = /\/(?<language>\S{2})\//.exec(pathname);

  if (!regexResult) return '';

  return regexResult.groups?.language || '';
};

export const event = (eventName: string, payload: Record<string, unknown>) => {
  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ event_data: null });

    window.dataLayer.push({
      event: eventName,
      event_data: payload,
      user_data: getUserData(),
    });
  }
};

export const pageView = (payload: PageViewData, options?: PageViewOptions) => {
  const defaultOptions: PageViewOptions = { sendUserData: true };

  const computedOptions = _defaults(options, defaultOptions);

  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ page_data: null });

    const language = extractLanguageFromUrl();

    const pageViewPayload: PageViewPayload = {
      event: 'page_view',
      page_data: { language: language || 'en', ...payload },
    };

    if (computedOptions.sendUserData) {
      pageViewPayload.user_data = getUserData();
    }

    window.dataLayer.push(pageViewPayload);
  }
};

export const ecommerce = (eventName: string, payload: ECommerceData) => {
  if (process.client) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      ecommerce: payload,
      event: eventName,
    });
  }
};
