import _get from 'lodash/get';

import countries from '../countries';
import type { Country, Feature, Features } from '../types';

import { useCountryFromLanguage } from './useCountryFromLanguage';

export function useFeature<T extends Feature>(
  name: Features,
  enforceCountryValidation = true,
): ComputedRef<T | null> {
  const runtimeConfig = useRuntimeConfig();

  const country = useCountryFromLanguage(enforceCountryValidation);
  const countryFeatures = computed(() => {
    const countryFeatureFactory = countries[country.value];

    if (typeof countryFeatureFactory !== 'function') return null;

    return countryFeatureFactory(runtimeConfig);
  });

  const feature = computed<T>(
    () => _get(countryFeatures.value, name) as unknown as T,
  );

  return computed(() => {
    if (!feature.value || (feature.value && !feature.value.active)) return null;

    return feature.value;
  });
}

export function getFeatureForCountry<T extends Feature>(
  name: Features,
  country: string,
): T | null {
  const runtimeConfig = useRuntimeConfig();

  const countryFeatureFactory = countries[country as Country];

  if (typeof countryFeatureFactory !== 'function') return null;

  const countryFeatures = countryFeatureFactory(runtimeConfig);

  const feature = _get(countryFeatures, name) as unknown as T;

  if (!feature || (feature && !feature.active)) return null;

  return feature;
}
